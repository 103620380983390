$sm: 320px;
$md: 672px;
$lg: 1056px;
$xl: 1312px;
$max: 1584px;

$sm-minus-1: calc(#{$sm} - 1px);
$md-minus-1: calc(#{$md} - 1px);
$lg-minus-1: calc(#{$lg} - 1px);
$xl-minus-1: calc(#{$xl} - 1px);
$max-minus-1: calc(#{$max} - 1px);

.FAQ {
  h1 {
    margin-bottom: 3.75rem;
  }
  .question {
    margin-bottom: 3rem;
  }
  .aspect-ratio-box {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .faq-image {
    background-color: #93d7bb;
    background-image: url(./Housing_4.svg);
    margin-bottom: 3rem;
  }
  @media (max-width: $lg-minus-1) {
    .faq-image-hidden-sm-md {
      display: none;
    }
  }
}
