$sm: 320px;
$md: 672px;
$lg: 1056px;
$xl: 1312px;
$max: 1584px;

$sm-minus-1: calc(#{$sm} - 1px);
$md-minus-1: calc(#{$md} - 1px);
$lg-minus-1: calc(#{$lg} - 1px);
$xl-minus-1: calc(#{$xl} - 1px);
$max-minus-1: calc(#{$max} - 1px);

.Homepage {
  .bx--grid.lead {
    border-bottom: 1px solid #7f7f84;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: -40px;
      div {
        max-width: 311px;
      }
    }
    .right {
      @media (min-width: $md) {
        margin-top: -40px;
      }
      img {
        width: 100%;
      }
    }
    .select-your-city {
      margin-top: 0.75rem;
      margin-bottom: -1.5rem;
    }
  }
  .list {
    a {
      text-decoration: none;
      font-size: 50px;
      line-height: normal;
      letter-spacing: normal;
      color: #2c2c2c;
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg {
        opacity: 0;
        width: 2.75rem;
        height: 2.75rem;
        margin-left: 6rem;
        fill: #f74300;
      }
      span {
        opacity: 0;
        font-size: 14px;
        font-style: italic;
        color: #f74300;
      }
      @media (max-width: $md-minus-1) {
        svg {
          margin-left: 3rem;
        }
      }
      @media (hover: none) {
        svg,
        span {
          display: none;
        }
      }
      @media (hover: hover) {
        &:hover {
          color: #f74300;
          svg,
          span {
            opacity: 1;
          }
        }
      }
    }
  }
}
