$sm: 320px;
$md: 672px;
$lg: 1056px;
$xl: 1312px;
$max: 1584px;

$sm-minus-1: calc(#{$sm} - 1px);
$md-minus-1: calc(#{$md} - 1px);
$lg-minus-1: calc(#{$lg} - 1px);
$xl-minus-1: calc(#{$xl} - 1px);
$max-minus-1: calc(#{$max} - 1px);

.City {
  .city-name {
    padding-bottom: 0;
  }
  h3 {
    margin-bottom: 25px;
  }
  .health-workers {
    border-bottom: 1px solid #7f7f84;
  }
  .aspect-ratio-box {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .health-workers-image {
    background-color: #fec720;
    background-image: url(./health-workers.svg);
  }
  .property-owners-image {
    background-color: #f29483;
    background-image: url(./property-owners.svg);
  }
  .health-workers-text,
  .property-owners-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    padding: 1rem;
    margin-left: -1rem;
    color: #f74300;
    cursor: pointer;
    div {
      display: flex;
    }
    span {
      opacity: 0;
      font-size: 14px;
      font-style: italic;
      color: #f74300;
      svg {
        margin-left: 0.25rem;
        width: 14px;
      }
    }
    svg {
      fill: #f74300;
      margin-left: 1rem;
    }
    &:hover {
      span {
        opacity: 1;
      }
    }
  }
  @media (max-width: $md-minus-1) {
    .health-workers-image-container,
    .property-owners-image-container {
      padding-bottom: 2rem;
    }
  }
}
