.language-toggle {
  // opacity: 0;
  // pointer-events: none;
  .bx--toggle-input {
  }
  .bx--toggle-input__label {
    .bx--toggle__switch {
      .bx--toggle__text--off {
      }
      .bx--toggle__text--on {
      }
    }
    .bx--toggle__switch::before {
      border-radius: 0;
      width: 3.5rem;
      background-color: #fec720;
    }
    .bx--toggle__switch::after {
      padding: 1px 0 0 4px;
      border-radius: 0;
      width: 1.5rem;
      content: "En";
    }
  }
  .bx--toggle-input:checked
    + .bx--toggle-input__label
    > .bx--toggle__switch::before {
    background-color: #f29483;
  }
  .bx--toggle-input:checked
    + .bx--toggle-input__label
    .bx--toggle__switch::after {
    content: "Fr";
  }
}
