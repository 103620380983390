html {
  background-color: #f2f1ed;
}

.App {
  font-family: "Karla", serif;
  background-color: #f2f1ed;
  .bx--grid {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .bx--row {
    margin-left: 0;
    margin-right: 0;
  }

  .bx--grid.header {
    border-bottom: 1px solid #7f7f84;
    .bx--row {
      height: 4rem;
      align-items: center;
      font-weight: bold;
      a {
        text-decoration: none;
        color: black;
      }
    }
  }

  .bx--grid.footer {
    border-top: 1px solid #7f7f84;
    .bx--row {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
    }
    svg {
      height: 1rem;
      width: 1rem;
      fill: #f74300;
      margin-left: 0.5rem;
    }
    .social-media-links {
      margin-left: -0.5rem;
      margin-bottom: 1rem;
    }
  }

  .bx--grid.header,
  .bx--grid.footer {
    padding-top: 0;
    padding-bottom: 0;
  }

  h1,
  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 1rem;
    span {
      font-weight: normal;
    }
  }

  h3,
  h4 {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
  }

  h4 {
    margin-bottom: 0.5rem;
    max-width: 300px;
  }

  p {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 1rem;
    span.italic {
      font-style: italic;
    }
    span.bold {
      font-weight: bold;
    }
    span.inline-icon {
      display: inline;
    }
  }

  a {
    color: #f74300;
  }

  ul {
    margin-bottom: 1rem;
    li {
      list-style: disc;
      color: #161616;
      padding-bottom: 0.5rem;
    }
  }
}
